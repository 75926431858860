<template>
	<app-header v-if="!isPageNotFound" />
	<router-view />
	<app-footer v-if="!isPageNotFound" />
	<chat-comp />
	<!-- <scroll-top v-if="!isPageNotFound" /> -->
	<cookies-modal v-if="!store.cookieConsent"></cookies-modal>
</template>

<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useHead } from "@unhead/vue";
import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const route = useRoute();

const canonicalLink = computed(() => {
	const baseUrl = "https://enonsolutions.com";
	return baseUrl + route.path;
});

setupHead();

function setupHead() {
	useHead({
		link: [
			{
				rel: "canonical",
				href: canonicalLink.value,
			},
		],
	});
}

watch(canonicalLink, () => {
	setupHead();
});

const isPageNotFound = computed(() => {
	if (route.name === "not-found") {
		return true;
	} else {
		return false;
	}
});

onMounted(async () => {
	await store.getIp();
	store.userReferrer = document.referrer;
	await store.getConfig();
});
</script>

<style lang="scss">
@import "./assets/css/base.css";

.grecaptcha-badge {
	visibility: hidden;
	opacity: 0;
}

@font-face {
	font-family: Archivo, sans-serif;
	font-weight: 600;
	src:
		local("Archivo"),
		url("./assets/fonts/archivo.ttf") format("truetype");
}

.wrapper {
	max-width: 1500px;
	margin: 0 auto;
}

#app {
	height: 100%;

	// overflow-x: hidden;
}
</style>
