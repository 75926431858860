<script setup lang="ts">
import { useDefaultStore } from "@/store";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import notifSound from "@/assets/audio/livechat-notification.mp3";

dayjs.extend(utc);

interface IChatMessage {
	cms_date: string;
	cms_message: string;
	cms_from: string;
	cus_id: number | null;
}

interface IUserData {
	token: string;
	cus_id: number;
	referrer: string | null;
}

const store = useDefaultStore();
const messageInput = ref("");
const allMessages = ref<IChatMessage[]>([]);
const token = ref<string | null>(null);
const cusId = ref<number | null>(null);
const referrer = ref<string | null>(null);
const wsChatmessage = ref<any>(null);
const wsChatusers = ref<any>(null);
const typingBool = ref(false);
const userData = ref<IUserData | null>(null);

async function sendMessage() {
	if (!messageInput.value) return;
	const params = {
		token: token.value,
		message: messageInput.value,
		from: "user",
	};

	try {
		const res = await axios.post(
			`${store.leadsRootApi}chatmessages/chatmessages-add`,
			params,
		);

		// Check if WebSocket is open before sending
		if (
			wsChatmessage.value &&
			wsChatmessage.value.readyState === WebSocket.OPEN
		) {
			wsChatmessage.value.send(
				JSON.stringify({
					type: "message",
					data: {
						cms_message: messageInput.value,
						cms_date: new Date(),
						cus_id: cusId.value,
						cms_from: "user",
					},
				}),
			);
		}

		if (wsChatusers.value && wsChatusers.value.readyState === WebSocket.OPEN) {
			wsChatusers.value?.send(
				JSON.stringify({
					type: "new_message",
					usr_id: null,
					data: {
						cus_id: cusId.value,
					},
				}),
			);
		}

		resetChatBox();
	} catch (e: any) {
		console.error(e);
	}
}

function resetChatBox() {
	// Clear input field after sending
	messageInput.value = "";
	adjustTextareaHeight(
		{
			target: document.querySelector(".chat-box-bottom-input-item"),
		} as Event,
		true,
	);
}

function adjustscrollHeight(time: number, behavior: ScrollBehavior) {
	setTimeout(() => {
		// Scroll to the bottom of the chat box
		const chatBoxCenterWrapper = document.querySelector(
			".chat-box-center-wrapper",
		) as HTMLElement;
		if (chatBoxCenterWrapper) {
			chatBoxCenterWrapper.scrollTo({
				top: chatBoxCenterWrapper.scrollHeight,
				behavior: behavior,
			});
		}
	}, time);
}

function onClickOpenChat() {
	store.isOpenChatBox = !store.isOpenChatBox;
	adjustscrollHeight(0, "instant");

	if (token.value) {
		getChatuserByToken(token.value);
	} else {
		getToken();
	}
}

async function getToken() {
	await store.getIp();
	const params = {
		referrer: store.userReferrer,
		ip: store.userIP,
		city: store.userCity,
		country_code: store.userCountryCode,
	};

	try {
		const res = await axios.post(
			`${store.leadsRootApi}chatmessages/chatusers-add`,
			params,
		);
		userData.value = res.data.data;
		if (userData.value) {
			token.value = userData.value.token;
			cusId.value = userData.value.cus_id;
			referrer.value = userData.value.referrer;
		}

		if (token.value) {
			localStorage.setItem("chatToken", token.value);

			chatusersWsConn(token.value, cusId.value, true);
		}
	} catch (e: any) {
		console.error(e);
	}
}

async function getChatuserByToken(token: string) {
	const params = {
		token,
	};
	try {
		const res = await axios.get(
			`${store.leadsRootApi}chatmessages/chatusers-get`,
			{ params },
		);
		if (res.data.data && res.data.data.length > 0) {
			cusId.value = res.data.data[0].cus_id;
			chatusersWsConn(token, cusId.value);
		}
	} catch (e: any) {
		console.error(e);
	}
}

async function getChatMessages(token: string) {
	const params = {
		token,
	};
	try {
		const res = await axios.get(
			`${store.leadsRootApi}chatmessages/chatmessages-get`,
			{ params },
		);
		allMessages.value = res.data.data;
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

function chatusersWsConn(
	token: string,
	cus_id: number | null,
	isNew?: boolean,
) {
	if (wsChatusers.value) {
		wsChatusers.value.close();
	}
	// if (!wsChatusers.value) {
	wsChatusers.value = new WebSocket(
		`wss://${store.leadsDomain}/api/chatmessages/chatusers-ws`,
	);
	// }

	wsChatusers.value.onopen = (evt: any) => {
		if (isNew) {
			wsChatusers.value?.send(
				JSON.stringify({
					type: "new_chatuser",
					data: {
						cus_token: token,
						cus_id: cus_id,
						cus_utc_last_activity: dayjs.utc().format("YYYY-MM-DD HH:mm:ss"),
						cus_referrer: store.userReferrer,
						cus_city: store.userCity,
						cus_country_code: store.userCountryCode,
					},
				}),
			);
		}

		chatmessagesWsConn(token, cus_id);
	};

	wsChatusers.value.onerror = (evt: any) => {
		console.log(evt);
	};

	wsChatusers.value.onclose = (evt: any) => {
		console.log(evt);
		console.log("WebSocket closed");
	};
}

async function chatmessagesWsConn(token: string, cus_id: number | null) {
	if (wsChatmessage.value) {
		wsChatmessage.value.close();
	}
	// if (!wsChatmessage.value) {
	wsChatmessage.value = new WebSocket(
		`wss://${store.leadsDomain}/api/chatmessages/chatmessages-ws?token=${token}`,
	);
	// }

	wsChatmessage.value.onopen = (evt: any) => {
		console.log("Successful connection");
	};

	wsChatmessage.value.onerror = (evt: any) => {
		console.log(evt);
	};

	wsChatmessage.value.onclose = (evt: any) => {
		console.log(evt);
		console.log("WebSocket closed");
	};

	wsChatmessage.value.onmessage = async (event: { data: string }) => {
		let data = JSON.parse(event.data);
		let dataMessage = JSON.parse(data.message);
		if (dataMessage.type === "message") {
			if (dataMessage.data.cms_from === "admin") {
				const soundNotif = new Audio(notifSound);
				store.notificationSound(soundNotif);
			}
			const item = {
				cms_message: dataMessage.data.cms_message,
				cms_date: dataMessage.data.cms_date,
				cms_from: dataMessage.data.cms_from ?? "user",
				cus_id: cus_id,
			};
			allMessages.value.push(item);

			if (store.availBool === 0) {
				const isFirst = await checkChatuserAvailable(token);

				setTimeout(async () => {
					if (!isFirst) return;

					await setChatuserAvailable(token);

					if (
						wsChatmessage.value &&
						wsChatmessage.value.readyState === WebSocket.OPEN
					) {
						wsChatmessage.value.send(
							JSON.stringify({
								type: "message",
								data: {
									cms_message:
										"It’s currently outside of business hours. Please send your email or phone number, and I’ll get back to you. Thank you!",
									cms_date: new Date(),
									cus_id: cusId.value,
									cms_from: "admin",
								},
							}),
						);
					}
				}, 1000);
			}
		} else if (
			dataMessage.type === "typing" &&
			dataMessage.data.cms_from !== "user"
		) {
			typingBool.value = true;
		} else if (
			dataMessage.type === "empty" &&
			dataMessage.data.cms_from !== "user"
		) {
			typingBool.value = false;
		}

		adjustscrollHeight(300, "smooth");
	};
}

function adjustTextareaHeight(event: Event, handleSubmit: boolean = false) {
	const textarea = event.target as HTMLTextAreaElement;
	textarea.style.height = "22px";
	if (!handleSubmit) textarea.style.height = `${textarea.scrollHeight}px`;
}

function handleKeyDown(event: KeyboardEvent) {
	if (event.key === "Enter" && !event.shiftKey) {
		event.preventDefault();
		sendMessage();
	} else if (event.key === "Enter" && event.shiftKey) {
		return;
	}
}

async function checkChatuserAvailable(token: string) {
	const params = {
		token,
	};
	try {
		const res = await axios.get(
			`${store.leadsRootApi}chatmessages/chatusers-available-get`,
			{ params },
		);
		if (res.data.data) {
			if (res.data.data[0].cus_not_available) {
				return false;
			} else {
				return true;
			}
		}
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

async function setChatuserAvailable(token: string) {
	const params = {
		token,
	};
	try {
		const res = await axios.post(
			`${store.leadsRootApi}chatmessages/chatusers-available-edit`,
			params,
		);
		console.warn(res.data.message);
		return true;
	} catch (err: any) {
		console.warn(err.message);
		return false;
	}
}

watch(messageInput, (newValue, oldValue) => {
	if (newValue.length > 0 && oldValue.length === 0) {
		if (
			wsChatmessage.value &&
			wsChatmessage.value.readyState === WebSocket.OPEN
		) {
			wsChatmessage.value.send(
				JSON.stringify({
					type: "typing",
					data: {
						cms_from: "user",
					},
				}),
			);
		}
	} else if (newValue.length === 0 && oldValue.length > 0) {
		if (
			wsChatmessage.value &&
			wsChatmessage.value.readyState === WebSocket.OPEN
		) {
			wsChatmessage.value.send(
				JSON.stringify({
					type: "empty",
					data: {
						cms_from: "user",
					},
				}),
			);
		}
	}
});

onMounted(async () => {
	token.value = localStorage.getItem("chatToken");
	if (token.value) getChatMessages(token.value);
});
</script>

<template>
	<Teleport to="body">
		<div
			class="chat-box-main-wrapper"
			:class="{ 'chat-box-main-wrapper-animation': !store.isOpenChatBox }"
		>
			<div class="chat-button-wrapper" @click="onClickOpenChat()">
				<img
					class="chat-button-icon"
					src="@/assets/icons/chat-icon.svg"
					alt="chat image"
				/>
			</div>
			<div v-if="store.isOpenChatBox" class="chat-box-wrapper">
				<div class="chat-box-top-wrapper">
					<div class="chat-box-top-left">
						<div class="chat-box-top-left-image">
							<div class="chat-box-top-left-status"></div>
						</div>
					</div>
					<div class="chat-box-top-right">
						<div class="chat-box-top-right-name">Mladen Josifović</div>
						<div class="chat-box-top-right-position">Outreach Manager</div>
					</div>
				</div>
				<div class="chat-box-center-wrapper">
					<div
						class="chat-box-center-message-wrapper chat-box-center-message-wrapper-admin chat-box-center-message-wrapper-init"
					>
						Thanks for visiting us!
					</div>
					<div
						class="chat-box-center-message-wrapper chat-box-center-message-wrapper-admin chat-box-center-message-wrapper-init"
					>
						What can I help you with today? In case we get disconnected, what's
						your email?
					</div>
					<div
						v-for="(message, index) in allMessages"
						:key="index"
						class="chat-box-center-message-wrapper"
						:class="{
							'chat-box-center-message-wrapper-admin':
								message.cms_from === 'admin',
							'chat-box-center-message-wrapper-user':
								message.cms_from === 'user',
						}"
						:title="dayjs(message.cms_date).format('MMM, DD. YYYY. HH:mm:ss')"
					>
						<div class="chat-box-center-message-date">
							{{ dayjs(message.cms_date).format("HH:mm") }}
						</div>
						{{ message.cms_message }}
					</div>

					<div
						v-if="typingBool"
						class="chat-box-center-message-wrapper chat-box-center-message-wrapper-admin chat-box-center-message-wrapper-loader"
					>
						<div class="chat-box-center-message-loader"></div>
					</div>
				</div>
				<div class="chat-box-bottom-wrapper">
					<div class="chat-box-bottom-input-wrapper">
						<textarea
							v-model="messageInput"
							class="chat-box-bottom-input-item"
							rows="1"
							placeholder="Write a message"
							@keydown="handleKeyDown"
							@input="adjustTextareaHeight"
						/>
					</div>
					<div class="chat-box-bottom-button-wrapper" @click="sendMessage()">
						<img src="@/assets/icons/send-icon.svg" alt="send" />
					</div>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<style lang="scss" scoped>
.chat-box-main-wrapper {
	position: fixed;
	z-index: 5;
	height: 60px;
	aspect-ratio: 1 / 1;
	right: 20px;
	bottom: 20px;
	background-color: $app-color;
	border-radius: 50%;

	&.chat-box-main-wrapper-animation {
		animation-name: bounce-7;
		animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
		animation-duration: 2s;
		animation-iteration-count: infinite;
		transform-origin: bottom;
	}

	.chat-box-wrapper {
		position: absolute;
		bottom: calc(100% + 20px);
		right: 0;
		height: 530px;
		width: 376px;
		max-width: 90vw;
		display: flex;
		box-sizing: border-box;
		flex-direction: column;

		.chat-box-top-wrapper {
			border-radius: 8px 8px 0 0;
			height: 68px;
			background-color: $app-color;
			padding: 16px;
			display: flex;

			.chat-box-top-left {
				.chat-box-top-left-image {
					border-radius: 50%;
					background-color: $text-color;
					background-image: url("@/assets/images/logo.jpg");
					background-repeat: no-repeat;
					background-size: 90%;
					background-position: center;
					height: 32px;
					width: 32px;
					margin-right: 16px;
				}
			}

			.chat-box-top-right {
				.chat-box-top-right-name {
					font-weight: 600;
					margin-right: 8px;
					font-size: 20px;
					line-height: 20px;
				}

				.chat-box-top-right-position {
					font-size: 10px;
					line-height: 10px;
					margin-left: 1px;
				}
			}
		}

		.chat-box-center-wrapper {
			flex: 1;
			padding: 12px;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			scrollbar-width: none;
			-ms-overflow-style: none;
			background-color: rgb(13, 16, 21);
			border-left: 1px solid $text-color;
			border-right: 1px solid $text-color;

			.chat-box-center-wrapper::-webkit-scrollbar {
				display: none;
			}

			.chat-box-center-message-wrapper {
				position: relative;
				min-width: 100px;

				&.chat-box-center-message-wrapper-admin {
					border: 1px solid transparent;
					border-radius: 0 8px 8px;
					margin-bottom: 12px;
					max-width: 90%;
					align-self: flex-start;
					padding: 12px 12px 24px;
					word-wrap: break-word;
					background-color: $text-color;
					color: $section-back;
				}

				&.chat-box-center-message-wrapper-user {
					border: 1px solid transparent;
					border-radius: 8px 8px 0;
					margin-bottom: 12px;
					max-width: 90%;
					align-self: flex-end;
					padding: 12px 12px 24px;
					word-wrap: break-word;
					background-color: rgba($app-color, 0.2);
					color: $section-back;
				}

				&.chat-box-center-message-wrapper-init {
					padding: 12px;
				}

				&.chat-box-center-message-wrapper-loader {
					padding: 12px;
					display: flex;
					justify-content: center;
					align-items: center;

					.chat-box-center-message-loader {
						width: 6px;
						aspect-ratio: 1;
						border-radius: 50%;
						animation: l5 1s infinite linear alternate;

						@keyframes l5 {
							0% {
								box-shadow:
									12px 0 #fff,
									-12px 0 #fff2;
								background: #fff;
							}
							33% {
								box-shadow:
									12px 0 #fff,
									-12px 0 #fff2;
								background: #fff2;
							}
							66% {
								box-shadow:
									12px 0 #fff2,
									-12px 0 #fff;
								background: #fff2;
							}
							100% {
								box-shadow:
									12px 0 #fff2,
									-12px 0 #fff;
								background: #fff;
							}
						}
					}
				}

				.chat-box-center-message-date {
					position: absolute;
					bottom: 6px;
					right: 12px;
					color: $text-placeholder;
					font-size: 10px;
				}
			}
		}

		.chat-box-bottom-wrapper {
			border-radius: 0 0 8px 8px;
			min-height: 50px;
			display: flex;
			box-sizing: border-box;
			background-color: $text-color;

			.chat-box-bottom-input-wrapper {
				width: 85%;
				box-sizing: border-box;
				padding: 12px 0 12px 12px;
				display: flex;
				align-items: center;

				.chat-box-bottom-input-item {
					width: 100%;
					background-color: transparent;
					font-size: 16px;
					height: 22px;
					line-height: 18px;
					max-height: 57px;
					resize: none;
					font-family: Archivo, sans-serif;

					&::placeholder {
						font-size: 16px;
						line-height: 18px;
						font-family: Archivo, sans-serif;
					}

					&::-webkit-scrollbar {
						width: 4px;
					}

					&::-webkit-scrollbar-track {
						background-color: $text-color;
					}

					&::-webkit-scrollbar-thumb {
						background-color: $medium-gray;
						border-radius: 4px;
					}

					&::-webkit-scrollbar-thumb:hover {
						background-color: rgba($medium-gray, 0.4);
					}
				}
			}

			.chat-box-bottom-button-wrapper {
				width: 15%;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					transition: opacity 0.3s ease;
					filter: brightness(0) saturate(100%) invert(56%) sepia(47%)
						saturate(413%) hue-rotate(85deg) brightness(95%) contrast(91%);
				}

				&:hover {
					img {
						opacity: 0.6;
					}
				}
			}
		}
	}

	.chat-button-wrapper {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		.chat-button-icon {
			width: 60%;
			height: 60%;
		}
	}

	&:hover {
		animation-name: unset;
	}
}

@keyframes bounce-7 {
	0% {
		transform: scale(1, 1) translateY(0);
	}

	10% {
		transform: scale(1.1, 0.9) translateY(0);
	}

	30% {
		transform: scale(0.9, 1.1) translateY(-30px);
	}

	40% {
		transform: scale(1.05, 0.95) translateY(0);
	}

	47% {
		transform: scale(1, 1) translateY(-7px);
	}

	64% {
		transform: scale(1, 1) translateY(0);
	}

	100% {
		transform: scale(1, 1) translateY(0);
	}
}
</style>
